import * as React from "react";
import { graphql } from "gatsby";

import "./terms.scss";
import Shell from "../components/shell";

interface IProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
          }
          html: string
        }
      }[]
    }
  }
}

export default class extends React.Component<IProps, {}> {
  render(){

    let data = this.props.data.allMarkdownRemark.edges[0].node;

    return (
      <Shell>
      <div className="fixed-width p-top-xlarge terms p-bottom-xlarge">
          <img src={require('../assets/logo_black.svg')}/>
      <h1 className="fg-brand-tertiary">{data.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: data.html }}/>
      </div>
      </Shell>
    )
  }
}

export const query = graphql`
query {
  allMarkdownRemark(filter: { fileAbsolutePath: { glob: "**/content/pages/terms.md" } }) {
    edges{
      node{
        frontmatter {
          title
        }
        html
      }
    }
  }
}`;
